import './App.css';
import EssentAISinglePage from './components/EssentAISinglePage';
import ContactUs from './components/ContactUs';
import OurSolutions from './components/OurSolutions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';



function App() {
  return (
    <>
    
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<EssentAISinglePage/>} />
          <Route path="/contactus" element={<ContactUs/>} />
          <Route path="/oursolutions"  element={<OurSolutions/>} />
          <Route path="/dashboard"  element={<Dashboard/>} />
        </Routes>
      </Router>

    </div>
    </>
  );
}

export default App;