import React from 'react'
import { X, Code, ExternalLink } from 'lucide-react'

const StatBadge = ({ label, value }) => (
  <span className="inline-flex items-center rounded-md bg-purple-400/10 px-2 py-1 text-xs font-medium text-purple-400 ring-1 ring-inset ring-purple-400/30">
    {label}: {value}
  </span>
)

const Modal = ({ onClose, name, longDescription, problemsSolved, techStack, demoLink, stats }) => (
  <div className="fixed inset-0 backdrop-blur-md bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-auto">
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg max-w-3xl w-full shadow-2xl text-gray-100 relative">
      <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-purple-500 via-pink-500 to-blue-500"></div>
      <button 
        onClick={onClose} 
        className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-200"
        aria-label="Close modal"
      >
        <X className="h-6 w-6" />
      </button>
      
      <div className="p-8">
        <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-blue-500 mb-4">
          {name}
        </h2>
        <p className="text-gray-300 mb-6 text-lg">{longDescription}</p>
        

        <div className="mb-6">
          <h3 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-blue-500 mb-4">
            Problem Solved
          </h3>
          <p className="text-gray-400 mb-4">{problemsSolved}</p>
          <div className="flex flex-wrap gap-2">
            {Object.entries(stats).map(([key, value]) => (
              <StatBadge key={key} label={key} value={value} />
            ))}
          </div>
        </div>


        <div className="mb-6">
          <h3 className="text-xl font-semibold text-purple-300 mb-3 flex items-center">
            <Code className="mr-2" /> Tech Stack
          </h3>
          <div className="flex flex-wrap gap-2">
            {techStack.map((tech, index) => (
              <span key={index} className="bg-purple-900 text-purple-200 px-3 py-1 rounded-full text-sm font-medium">
                {tech}
              </span>
            ))}
          </div>
        </div>

        
        
        <a
          href={demoLink}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 transform hover:scale-105"
        >
          Test Demo <ExternalLink className="ml-2 h-5 w-5" />
        </a>
      </div>
    </div>
  </div>
)

export default Modal