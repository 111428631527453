'use client';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Send } from 'lucide-react';
import emailjs from '@emailjs/browser';
import logo1 from './Images/7.png'




const ContactUs = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    from_company: '',
    from_link: '',
    message: ''
  });
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = ()=>setIsOpen(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Replace with your EmailJS service, template IDs, and public key
    const serviceID = 'service_ik8sxkv';
    const templateID = 'template_v6nky6w';
    const publicKey = 'lk4RZ_-9tNvwhdcrf';
    emailjs
      .sendForm(serviceID, templateID, form.current, publicKey)
      .then(
        () => {
          console.log('SUCCESS!');
          setIsOpen(true)
          setFormData({
            from_name: '',
            from_email: '',
            from_company: '',
            from_link: '',
            message: ''
          })
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('Oops! Something went wrong. Please try again.');
        }
      );

    // Reset the form
    setFormData({
      from_name: '',
      from_email: '',
      from_company: '',
      from_link: '',
      message: '',
    });
  };


  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <header className="py-10 bg-gradient-to-br from-blue-900 via-purple-800 to-gray-900">
      <nav className="w-max bg-transparent">
  <div className="container mx-auto flex items-center justify-start h-8">
    <a href="/" className="flex items-center">
      <img src={logo1} alt="Logo" className="h-56 w-auto" /> {/* Static logo size */}
    </a>
  </div>
</nav>

        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-blue-500">
            Contact Us
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Ready to explore how our AI solutions can transform your business? Get in touch with us today.
          </p>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12">
        <div className="max-w-2xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8">
          <form ref={form} onSubmit={sendEmail} className="space-y-6">
            <div>
              <label htmlFor="from_name" className="block text-lg font-semibold text-gray-300">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md px-4 bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50 h-10"
              />
            </div>
            <div>
              <label htmlFor="from_email" className="block text-lg font-semibold text-gray-300">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-4 rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50 h-10"
              />
            </div>
            <div>
              <label htmlFor="from_company" className="block text-lg font-semibold text-gray-300">
                Company
              </label>
              <input
                type="text"
                id="company"
                name="from_company"
                value={formData.from_company}
                onChange={handleChange}
                className="mt-1 block w-full px-4 rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50 h-10"
              />
            </div>
            <div>
              <label htmlFor="from_link" className="block text-lg font-semibold text-gray-300">
                LinkedIn/Company Website
              </label>
              <input
                type="url"
                id="link"
                name="from_link"
                value={formData.from_link}
                onChange={handleChange}
                placeholder='Recommended'
                className="mt-1 block w-full px-4 rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50 h-10"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-lg font-semibold text-gray-300">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md px-4 bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Send Message
                <Send className="ml-4 h-5 w-5" />
              </button>
            </div>
            {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-gray-900 rounded-lg max-w-md w-full p-6 text-gray-100">
            <div className="text-center">
              <div className="flex items-center justify-center mb-4">
                <svg className="w-12 h-12 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
              <h2 className="text-2xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-blue-500">
                Message Sent
              </h2>
              <p className="text-gray-300 mb-4">
                Your message has been successfully sent. We'll get back to you soon.
              </p>
            </div>
            <div className="text-center mb-6">
              <p className="text-gray-300">
                Thank you for reaching out to us. Our team will review your message and respond as soon as possible.
              </p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full text-sm font-semibold transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
          </form>
        </div>
      </main>

      <footer className="bg-gray-900 text-gray-300 h-24 pt-8 border-t border-gray-800">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Essent AI. All rights reserved.</p>
          <Link to="/oursolutions" className="text-purple-400 hover:text-purple-300 mt-2 inline-block">
            Back to Our Solutions
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default ContactUs;
