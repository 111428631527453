import React, { useState } from 'react';
import { MessageSquare, Cloud, Brain } from 'lucide-react';
import logo1 from './Images/7.png';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';

const systems = [
    {
      name: "Predictive Analytics Model",
      description: "Check out a Machine Learning model built for one of our previous clients.",
      longDescription: "This Predictive Analytics Model assists clients in identifying the most suitable accessories for vehicles using partial user inputs. It addresses the common problem where customers lack detailed knowledge of their vehicle's specifications. The model leverages advanced machine learning techniques to achieve an accuracy of 97% and provides over 60% actionable insights, streamlining the decision-making process for users.",
      problemsSolved: "Customers often struggled to identify the right accessories for their vehicle due to lack of detailed vehicle specifications which led to indecision. Inefficient accessory selection processes would result in lost sales opportunities.",
      icon: <Brain className="h-8 w-8 text-purple-400" />,
      stats: { accuracy: "97%", insights: "+60%" },
      company: "Universal",
      techStack: ["Full Stack Web-app", "Scikit-Learn", "Pandas", "Machine Learning", "UI/UX"],
      demoLink: "https://snapfront.netlify.app/"
    },
    {
      name: "NLP - Chatbot",
      description: "A high-end NLP chatbot trained on general knowledge, customizable for any business model.",
      longDescription: "Our NLP Chatbot leverages cutting-edge natural language processing to engage users with dynamic, context-aware conversations. This chatbot can be tailored to fit various business models, providing automated customer support and enhancing user experience. It resolves common customer service challenges, such as handling inquiries efficiently and reducing response times.",
      problemsSolved: "Long wait times for customer support, inconsistent responses to common queries, and the need for 24/7 availability were major pain points for businesses. The chatbot addresses these issues by providing instant, accurate responses at any time.",
      icon: <MessageSquare className="h-8 w-8 text-purple-400" />,
      stats: { efficiency: "+48%", cost: "-25%" },
      company: "Universal",
      techStack: ["Python", "Open AI"],
      demoLink: "https://essentchatbot.netlify.app"
    },
    {
      name: "Third Party Tools",
      description: "Automate tasks with tools like Zapier, GoHighLevel, Make.com, Airtable, etc.",
      longDescription: "Watch the Video by clicking the button Below to see how our Third Party Tools integration automates various business processes, reducing manual effort and increasing efficiency. By utilizing platforms like Zapier and Airtable, businesses can streamline operations, improve ROI by 150%, and save up to 40% in time. This solution effectively addresses common inefficiencies in task management and workflow automation.",
      problemsSolved: "Businesses were struggling with disconnected systems, manual data entry, and time-consuming repetitive tasks. Our integration of third-party tools automates these processes, reducing errors and freeing up valuable time for more strategic work.",
      icon: <Cloud className="h-8 w-8 text-purple-400" />,
      stats: { roi: "+150%", time: "-40%" },
      company: "Universal",
      techStack: ["Zapier", "Make.com", "Airtable", "VAPI", "Twilio"],
      demoLink: "https://www.canva.com/design/DAGO5gbRg8E/0IzAvze6oVKZetH9QowoIg/edit?utm_content=DAGO5gbRg8E&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
    }
  ];
  

const StatBadge = ({ label, value }) => (
  <span className="inline-flex items-center rounded-md bg-purple-400/10 px-2 py-1 text-xs font-medium text-purple-400 ring-1 ring-inset ring-purple-400/30">
    {label}: {value}
  </span>
);

export default function Dashboard() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedSystem, setSelectedSystem] = useState(null);

    const handleScheduleButton = () => {
        navigate('/contactus');
    };

    const openModal = (system) => {
        setSelectedSystem(system);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedSystem(null);
    };

    const SystemCard = ({ system }) => (
        <div 
          className="bg-gray-800 border border-gray-700 rounded-lg overflow-hidden hover:border-purple-400 transition-colors duration-300 cursor-pointer"
          onClick={() => openModal(system)}
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              {system.icon}
              <span className="bg-purple-500 text-white text-xs font-semibold px-2.5 py-0.5 rounded">
                {system.company}
              </span>
            </div>
            <h3 className="text-xl font-semibold text-purple-400 mb-4">{system.name}</h3>
            <p className="text-gray-400 mb-4">{system.description}</p>
            <div className="flex flex-wrap gap-2">
              {Object.entries(system.stats).map(([key, value]) => (
                <StatBadge key={key} label={key} value={value} />
              ))}
            </div>
          </div>
        </div>
    );

    return (
      <div className="min-h-screen flex flex-col bg-gray-900 text-gray-100 font-sans">
        <header className="bg-gray-800 shadow-md py-0">
          <div className="flex flex-row h-24 items-center justify-between w-full">
            <a href="/" className="flex items-center">
              <img src={logo1} alt="Logo" className="h-48 md:h-56 lg:h-60 w-auto" />
            </a>
            <button
              className="bg-purple-600 h-12 hover:bg-purple-700 text-white px-6 mx-3 py-2 rounded-full text-md font-semibold transition-all duration-300 transform hover:scale-105 ml-auto"
              onClick={handleScheduleButton}
            >
              Schedule a Consultation
            </button>
          </div>
        </header>

        <main className="container mx-auto px-4 py-8 flex-grow">
          <h2 className="text-2xl font-semibold mb-6 text-center text-purple-300">Our AI Systems</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {systems.map((system, index) => (
              <SystemCard key={index} system={system} />
            ))}
          </div>
        </main>

        {isOpen && selectedSystem && (
          <Modal
            onClose={closeModal}
            name={selectedSystem.name}
            longDescription={selectedSystem.longDescription}
            problemsSolved={selectedSystem.problemsSolved}
            techStack={selectedSystem.techStack}
            demoLink={selectedSystem.demoLink}
            stats={selectedSystem.stats}
          />
        )}

        <footer className="bg-gray-900 text-gray-300 h-28 pt-8 border-t border-gray-800">
          <div className="container mx-auto px-4 py-4 text-center">
            <p>&copy; 2024 Essent AI LLC. All rights reserved.</p>
          </div>
        </footer>
      </div>
    );
}